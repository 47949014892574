<template>
  <div class="page">
    <div class="top_box">
      <div class="pic_box">
        <div class="box_l"><span>*</span>活动图片:</div>
        <div class="box_r">
          <v-upload
            class="oItem-b_upload"
            :imgUrl.sync="form.proposalPic1"
            :activityPicture.sync="form.proposalPic"
            :maxCount="1"
            ref="load"
          ></v-upload>
        </div>
      </div>
      <div class="name_box">
        <div class="box_l"><span>*</span>活动名称:</div>
        <div class="box_r">
          <input
            v-model="form.activityName"
            type="text"
            placeholder="请输入活动名称"
          />
        </div>
      </div>
      <div class="place_box">
        <div class="box_l"><span>*</span>活动地点:</div>
        <div class="box_r">
          <input
            v-model="form.activityAddress"
            type="text"
            placeholder="请输入活动地点"
          />
        </div>
      </div>
      <div class="num_box">
        <div class="box_l"><span>*</span>活动人数:</div>
        <div class="box_r">
          <div class="step_box">
            <img src="./img/reduce.png" @click="stepChange('reduce')" />
            <div class="numChange">{{ form.limitCount }}</div>
            <img src="./img/increase.png" @click="stepChange('increase')" />
          </div>
        </div>
      </div>
      <div class="statr_box">
        <div class="box_l"><span>*</span>活动开始:</div>
        <div class="box_r">
          <div class="message" @click="seletTimeStart()">{{ starTime }}</div>
        </div>
      </div>
      <div class="end_box">
        <div class="box_l"><span>*</span>活动结束:</div>
        <div class="box_r">
          <div class="message" @click="seletTimeEnd()">{{ endTime }}</div>
        </div>
      </div>
    </div>
    <div class="bottom_box">
      <van-field
        rows="6"
        style="background: rgba(0, 0, 0, 0.04); border-radius: 16px"
        v-model="form.content"
        type="textarea"
        placeholder="简单介绍下活动吧~"
        maxlength="300"
        show-word-limit
      />
    </div>
    <div class="btn" @click="submit()">发布活动</div>
    <!-- 开始时间 -->
    <van-popup
      v-model="show_start"
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="popup_box">
        <div class="top_box">
          请选择活动开始时间
          <img src="./img/close_icon.png" @click="show_start = false" />
        </div>
        <div class="time_box">
          <div class="time_left">
            <div v-for="(item, index) in weekArray_start" :key="index">
              <div
                @click="selectWeekStart(item, index)"
                :class="['week_item', { active: item.select == true }]"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="time_right">
            <div class="hour_item" v-if="hourArray_start.length == 0">
              暂无可选择时间
            </div>
            <div v-for="(item, index) in hourArray_start" :key="index">
              <div
                @click="selectHourStart(item)"
                :class="['hour_item', { active: item.select == true }]"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 结束时间 -->
    <van-popup v-model="show_end" position="bottom" :style="{ height: '60%' }">
      <div class="popup_box">
        <div class="top_box">
          请选择活动结束时间
          <img src="./img/close_icon.png" @click="show_end = false" />
        </div>
        <div class="time_box">
          <div class="time_left">
            <div v-for="(item, index) in weekArray_end" :key="index">
              <div
                @click="selectWeekEnd(item, index)"
                :class="['week_item', { active: item.select == true }]"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="time_right">
            <div class="hour_item" v-if="hourArray_end.length == 0">
              暂无可选择时间
            </div>
            <div v-for="(item, index) in hourArray_end" :key="index">
              <div
                @click="selectHourEnd(item)"
                :class="['hour_item', { active: item.select == true }]"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createActivityUrl } from "./api.js";
export default {
  name: "publicationActivity",
  data() {
    return {
      form: {
        proposalPic: "",
        activityName: "",
        activityAddress: "",
        limitCount: 1,
        startDate: null,
        endDate: null,
        content: "",
      },
      id: null,
      timer: null,

      show_start: false,
      weekArray_start: [],
      hourArray_start: [],
      starTime: "请选择活动开始时间",
      starTimeNum: null,

      show_end: false,
      weekArray_end: [],
      hourArray_end: [],
      endTime: "请选择活动结束时间",
      endTimeNum: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.id = this.$route.query.id;
    this.getWeekDataStart();
    this.getHourDataStart();
    this.getWeekDataEnd();
    this.getHourDataEnd();
    this.getNowHourList_s();
    this.getNowHourList_e();
  },
  methods: {
    async submit() {
      //提交按钮
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.form.proposalPic) {
        this.$toast("请上传图片");
        return;
      }
      if (this.form.activityName == "") {
        this.$toast("活动名称不能为空！");
        return;
      }
      if (this.form.activityAddress == "") {
        this.$toast("活动地点不能为空！");
        return;
      }
      if (this.starTime == "请选择活动开始时间") {
        this.$toast("活动开始时间不能为空！");
        return;
      }
      if (this.endTime == "请选择活动结束时间") {
        this.$toast("活动结束时间不能为空！");
        return;
      }
      if (this.starTimeNum > this.endTimeNum) {
        this.$toast("活动开始时间不能大于活动结束时间！");
        return;
      }
      this.postData();
    },
    postData() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
        associationId: this.id,
        activityName: this.form.activityName,
        activityAddress: this.form.activityAddress,
        limitCount: this.form.limitCount,
        startDate: this.starTime,
        endDate: this.endTime,
        content: this.form.content,
        activityPicture: this.form.proposalPic,
      };
      this.$axios.post(`${createActivityUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast(res.msg);
          this.timer = setTimeout(() => {
            this.$router.replace({
              name: "releaseSuccess",
              query: {
                id: this.id,
              },
            });
          }, 200);
        } else {
        }
      });
    },
    stepChange(type) {
      //人数步进器
      if (type == "reduce") {
        if (this.form.limitCount == 1) {
          this.form.limitCount = 1;
        } else {
          this.form.limitCount--;
        }
      } else if (type == "increase") {
        this.form.limitCount++;
      }
    },
    // 结束时间
    selectWeekEnd(data, index) {
      this.weekArray_end.forEach((item) => {
        item.select = false;
      });
      data.select = true;
      if (index > 0) {
        this.getHourDataEnd();
      } else {
        this.getNowHourList_e();
      }
    },
    selectHourEnd(data) {
      let str1;
      let str2;
      this.hourArray_end.forEach((item) => {
        item.select = false;
      });
      this.weekArray_end.forEach((item) => {
        if (item.select == true) {
          str1 = item.name;
        }
      });
      data.select = true;
      str2 = data.name;
      this.endTime = str1 + " " + str2;
      this.endTimeNum = Number(str1.replace(/-/g, "") + str2.replace(/:/g, ""));
      this.form.endDate = new Date(this.endTime);
      console.log(this.endTime);
      console.log(this.form.endDate);
      this.show_end = false;
    },
    seletTimeEnd() {
      this.show_end = true;
    },
    getWeekDataEnd() {
      //获取一周时间数组
      let myDate = new Date();
      let dateArray = [];
      let dateTemp;
      let flag = 1;
      for (let i = 0; i < 7; i++) {
        let year = myDate.getFullYear();
        let month = myDate.getMonth() + 1;
        let date = myDate.getDate();
        month < 10 ? (month = "0" + month) : month;
        date < 10 ? (date = "0" + date) : date;
        dateTemp = year + "-" + month + "-" + date;
        let obj = {
          name: dateTemp,
          select: false,
        };
        dateArray.push(obj);
        myDate.setDate(myDate.getDate() + flag);
      }
      dateArray[0].select = true;
      this.weekArray_end = dateArray;
    },
    getHourDataEnd() {
      let hourArray = [];
      let hourTemp;
      for (let i = 6; i <= 21; i++) {
        let hourNum = i;
        hourTemp = i + ":00";
        let obj = {
          name: hourTemp,
          select: false,
        };
        hourArray.push(obj);
      }
      hourArray[0].select = true;
      this.hourArray_end = hourArray;
    },
    getNowHourList_e() {
      let date = new Date();
      let hour = date.getHours() + 1;
      let hourArray = [];
      let hourTemp;
      for (let i = hour; i <= 21; i++) {
        let hourNum = i;
        hourTemp = i + ":00";
        let obj = {
          name: hourTemp,
          select: false,
        };
        hourArray.push(obj);
      }
      if (hourArray.length > 1) {
        hourArray[0].select = true;
      }
      this.hourArray_end = hourArray;
    },
    // --------------------------------------------------------------------
    // 开始时间
    selectWeekStart(data, index) {
      this.weekArray_start.forEach((item) => {
        item.select = false;
      });
      data.select = true;
      if (index > 0) {
        this.getHourDataStart();
      } else {
        this.getNowHourList_s();
      }
    },
    selectHourStart(data) {
      let str1;
      let str2;
      this.hourArray_start.forEach((item) => {
        item.select = false;
      });
      this.weekArray_start.forEach((item) => {
        if (item.select == true) {
          str1 = item.name;
        }
      });
      data.select = true;
      str2 = data.name;
      this.starTime = str1 + " " + str2;
      this.starTimeNum = Number(
        str1.replace(/-/g, "") + str2.replace(/:/g, "")
      );
      this.form.startDate = new Date(this.starTime);
      console.log(this.starTime);
      console.log(this.form.startDate);
      this.show_start = false;
    },
    seletTimeStart() {
      this.show_start = true;
    },
    getWeekDataStart() {
      //获取一周时间数组
      let myDate = new Date();
      let dateArray = [];
      let dateTemp;
      let flag = 1;
      for (let i = 0; i < 7; i++) {
        let year = myDate.getFullYear();
        let month = myDate.getMonth() + 1;
        let date = myDate.getDate();
        month < 10 ? (month = "0" + month) : month;
        date < 10 ? (date = "0" + date) : date;
        dateTemp = year + "-" + month + "-" + date;
        let obj = {
          name: dateTemp,
          select: false,
        };
        dateArray.push(obj);
        myDate.setDate(myDate.getDate() + flag);
      }
      dateArray[0].select = true;
      this.weekArray_start = dateArray;
    },
    getHourDataStart() {
      let hourArray = [];
      let hourTemp;
      for (let i = 6; i <= 21; i++) {
        let hourNum = i;
        hourTemp = i + ":00";
        let obj = {
          name: hourTemp,
          select: false,
        };
        hourArray.push(obj);
      }
      hourArray[0].select = true;
      this.hourArray_start = hourArray;
    },
    getNowHourList_s() {
      let date = new Date();
      let hour = date.getHours() + 1;
      let hourArray = [];
      let hourTemp;
      for (let i = hour; i <= 21; i++) {
        let hourNum = i;
        hourTemp = i + ":00";
        let obj = {
          name: hourTemp,
          select: false,
        };
        hourArray.push(obj);
      }
      if (hourArray.length > 1) {
        hourArray[0].select = true;
      }
      this.hourArray_start = hourArray;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding-top: 30px;
  box-sizing: border-box;
  padding-bottom: 94px;

  .popup_box {
    width: 100%;
    height: 100%;

    .time_box {
      width: 100%;
      height: calc(100% - 78px);
      display: flex;

      .time_left {
        width: 258px;
        background: #f6f6f6;

        .week_item {
          height: 92px;
          width: 100%;
          text-align: center;
          line-height: 92px;
          font-size: 32px;
          color: rgba(0, 0, 0, 0.5);
        }

        .active {
          background-color: #fff;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .time_right {
        flex: 1;
        background-color: #fff;
        overflow: hidden;
        overflow-y: scroll;

        .hour_item {
          width: 100%;
          height: 92px;
          line-height: 92px;
          padding-left: 34px;
          box-sizing: border-box;
          border-bottom: 2px solid #e9e9e9;
          font-size: 32px;
          color: rgba(0, 0, 0, 0.5);
        }

        .active {
          font-weight: 600;
          color: #fe9717;
        }
      }
    }

    .top_box {
      width: 100%;
      height: 78px;
      text-align: center;
      line-height: 78px;
      border-bottom: 2px solid #e9e9e9;
      font-size: 32px;
      color: #151c39;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 26px;
        height: 26px;
      }
    }
  }

  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 124px;
  }

  .bottom_box {
    margin-top: 32px;
    width: 100%;
    padding: 0 54px;
    box-sizing: border-box;
  }

  .top_box {
    width: 100%;
    padding: 0 44px;
    box-sizing: border-box;

    .num_box {
      width: 100%;
      height: 118px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      line-height: 118px;
      display: flex;

      .box_l {
        width: 180px;
        font-size: 32px;
        color: #333333;

        span {
          color: #ff602a;
        }
      }

      .box_r {
        flex: 1;
        position: relative;

        .step_box {
          display: flex;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          .numChange {
            background-color: #f2f2f2;
            // min-width: 48px;
            padding: 0 15px;
            height: 48px;
            text-align: center;
            line-height: 48px;
            border-radius: 10px;
            margin: 0 8px;
          }

          img {
            display: block;
            width: 48px;
            height: 48px;
          }
        }
      }
    }

    .statr_box,
    .end_box,
    .name_box,
    .place_box {
      width: 100%;
      height: 118px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      line-height: 118px;
      display: flex;

      .box_l {
        width: 180px;
        font-size: 32px;
        color: #333333;

        span {
          color: #ff602a;
        }
      }

      .box_r {
        flex: 1;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.5);

        .message {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
          text-align: right;
        }

        input {
          width: 100%;
          height: 100%;
          text-align: right;
        }

        input::placeholder {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .pic_box {
      width: 100%;
      display: flex;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      padding-bottom: 34px;

      .box_l {
        width: 180px;
        font-size: 32px;
        color: #333333;

        span {
          color: #ff602a;
        }
      }

      .box_r {
        flex: 1;
      }
    }
  }
}
</style>
